/* Import Poppins font from Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;700&family=Roboto&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --green-color: #2aec7c;
  --gray-color: #2f2f2f;
  --white-color: #FFFFFF;

}
.home {
  perspective: 1500px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border-width: 0
}

.pointer-events-none {
  pointer-events: none
}

@keyframes beam-fade {
  0%,to {
      opacity: 1
  }

  50% {
      opacity: .5
  }
}

.animate-beam-fade {
  animation: beam-fade 10s linear infinite
}

@keyframes spin {
  to {
      transform: rotate(1turn)
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation: fadeInUp 2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 8s ease-in-out;
}

.card {
  font-weight: bold;
  padding: 1em;
  text-align: right;
  
  position: relative;
  
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  transform: rotate3d(1);
}
.card:hover {
  transition-duration: 500ms;

}

.card .glow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  
  background-image: radial-gradient(circle at 50% -20%, #ffffff22, #0000000f);
}

body {
  font-family: "Poppins", sans-serif;
  max-height: 100vh;
  
}

.home-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  max-height: 100vh;
  background: var(--gray-color);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.square {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.eclip {
  position: absolute;
  left: 0;
  top: -150px;
  z-index: 1;
}

.login-form-container {
   padding-bottom: 20px;
  font-size: 14px;
  font-family: inherit;
  color: var(--gray-color);
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
}

.login-form-container button:active {
  scale: 0.95;
}

.login-form-container .logo-container {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
}

.login-form-container .form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.login-form-container .form-group label {
  display: block;
  margin-bottom: 5px;
}

.login-form-container .form-group input[type="text"],
.login-form-container .form-group input[type="password"] {
  font-family: inherit;
}

.login-form-container .form-group input::placeholder {
  opacity: 0.5;
}

.login-form-container .form-group input[type="text"]:focus,
.login-form-container .form-group input[type="password"]:focus {
  outline: none;
}

.login-form-container .form-submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  color: #fff;
  background-color: #212121;
  border: none;
  width: 100%;
  padding: 12px 16px;
  font-size: inherit;
  gap: 8px;
  margin: 12px 0;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
}

.login-form-container .form-submit-btn:hover {
  background-color: #313131;
}

.login-form-container .link {
  color: #1778f2;
  text-decoration: none;
}

.login-form-container .forgot-password-link {
  align-self: flex-end;
  margin-top: -20px;
}

.login-form-container .signup-link {
  align-self: center;
  font-weight: 500;
}

.login-form-container .signup-link .link {
  font-weight: 400;
}

.login-form-container .link:hover {
  text-decoration: underline;
}

.login-form-container .line {
  width: 100%;
  height: 1px;
  background-color: #212121;
  opacity: 0.1;
}

.homepage-container {
  display: flex;
  position: relative;
  background-color: #ffff;
  border-radius: 20px;
  min-height: 90vh;
  max-height: 90vh;
  min-width: 80vw;
  height: 100%;
}

.homepage-container .side-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 104px;
  height: 90vh;
}

.site-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  height: 70px;
}
.side-nav {
  background-color: #343434;
  height: 237px;
  width: 58px;
  border-radius: 20px;
  margin-top: 50px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.side-nav .nav-items {
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.nav-items.activate {
  background-color: #cf6969;
  transition: background-color 0.3s ease, transform 0.3s ease;
  transform: scale(1.1);
}
.contact-nav {
  margin-bottom: 10px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-nav span {
  font-weight: 600;
  color: #343434;
  font-size: 0.8rem;
  writing-mode: vertical-lr; /* Orient text vertically */
  transform: rotate(180deg); /* Flip text to start from bottom */
  display: inline-block; /* To apply transformations */
}

.container-home {
  width: 80vw;
  z-index: 10;
}

.container-home .top-header-home {
  display: flex;
  align-items: center;
  height: 70px;
}
.search-home {
  width: 90%;
}
.button-login-home {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_login-home {
  text-decoration: none;
  font-size: 0.8rem;
  color: #dadada;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cf6969;
  border-radius: 10px;
  width: 104px;
  height: 32px;
}
.btn_login-home:hover {
  background-color: #343434;
}
.container-main-home {
  height: calc(90vh - 70px - 120px);
  display: flex;
}
.footer-container-main-home {
  height: 120px;
  display: flex;
}

.left-container-main-home {
  width: 50%;
}

.hero {
}
.hero-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #393939;
}
.hero-description {
  text-transform: uppercase;
  font-size: 1.2rem;
  color: #393939;
  font-weight: 400;
}

.hero-detail {
  margin-top: 10px;
  font-size: 0.8rem;
  color: #292828;
  font-weight: 400;
}
.button-contact-main-home {
  margin-top: 10px;
  width: 134px;
  height: 32px;
  background-color: #343434;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  color: #dadada;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.btn-contact-main-home {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.button-contact-main-home:hover {
  background-color: #cf6969;
  cursor: pointer;
  color: #292828;
}

.notify-home {
  margin-top: 20px;
  width: 100%;
  height: 200px;
  background-color: #343434;
  opacity: 0.2;
  border-radius: 10px;
}
.sliderContainer {
  position: relative;
  width: 100%;
  height: 500px; /* Adjust as needed */
  overflow: hidden;
}
.slider {
  overflow: hidden;
}

/* Add other styles related to the slider here, like navigation buttons */
.navButton {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  /* Add more styling for the navigation buttons */
}

.slide-home {
  width: 100%;
  height: 200px;
}
.slide-home-container {
  width: 100%;
  position: relative;
}
.slider-wrapper {
  width: 100%;
}

.slider {
  display: flex;
  flex-direction: row; /* Mỗi item sẽ nằm cạnh nhau theo chiều ngang */
  width: 100%;
  overflow-x: hidden; /* Cho phép cuộn nếu các item vượt quá chiều rộng */
  height: 200px;
}

.slider-item {
  width: 100%;
  transition: all 1s ease-in-out;
}
.hide {
  display: none;
}
.buttons-wrapper {
  width: 400px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  margin-top: -15px;
}

/* indicators */
.indicators {
  position: absolute;
  bottom: 0px;
  z-index: 2 !important;
}

.indicators div {
  display: inline-block;
  margin-right: 15px;
  width: 10px;
  height: 10px;
  font-size: 16px;
  line-height: 30px;
  border-radius: 100%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  color: #333;
  text-indent: -9999px;
  cursor: pointer;
  transition: all 0.2s linear;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.indicators li:last-child {
  margin-right: 0;
}

.active-indicator {
  background: rgba(0, 0, 0, 0.3) !important;
  color: #fff !important;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.slider-feature {
  width: 100%;
  overflow: hidden;
}
.feature-title {
  text-transform: uppercase;
  margin-top: 30px;
  color: #d84040;
  font-size: 1.2rem;
  font-weight: 600;
}
.feature-detail {
  width: 50%;
  margin-top: 10px;
  color: #292828;
  font-size: 0.8rem;
}

.footer-home-tag-groups {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
}
.footer-home-comunity-groups {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 10%;
}

.first-tag-groups {
  display: flex;
  gap: 20px;
}

.second-tag-groups {
  margin-top: 10px;
  display: flex;
  gap: 20px;
}
.tag-items {
  gap: 5px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  border-radius: 10px;
  color: #dadada;
  font-size: 0.8rem;
  width: fit-content;
  background-color: #343434;
  animation-name: fadeInUp;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.comunity-notify {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #343434;
  width: 36px;
  height: 36px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.right-container-main-home {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  position: relative;
}
.human {
  position: absolute;
  left: 100px;
  z-index: 11;
}
.back-screen {
  position: absolute;
  top: 100px;
  left: 250px;
}
.back-screen2 {
  position: absolute;
  top: 100px;
  left: 450px;
}
.screen-left {
  position: absolute;
  top: 150px;
  left: 150px;
  z-index: 12;
  animation: moveScreen 3s infinite ease-in-out;
}
.screen-mid {
  position: absolute;
  top: 230px;
  left: 300px;
  z-index: 12;
  animation: moveScreen 3s infinite ease-in-out;
}
.screen-right {
  position: absolute;
  top: 140px;
  left: 510px;
  z-index: 12;
  animation: moveScreen 3s infinite ease-in-out;
}

@keyframes moveScreen {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(
      -20px
    ); /* Adjust the value to match the desired movement */
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: gradient 15s ease infinite;
}

.app {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #f3f6fe;
}

.sidebar {
  position: fixed;
  z-index: 11;
  top:0px;
  left: 0px;
  background-color: #050505;
  max-width: 247px;
  min-width: 247px;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

li .active {
  border-radius: 5px;
}

li.active a {
  
  font-weight: bold; /* Độ đậm cho văn bản mục active */
}

.app-container {
  background-color: #f3f6fe;

}
.sidebar.closed {
  transform: translateX(-100%); 
  transition: transform 1s ease-in-out;
}

.sidebar.open {
  transform: translateX(0);
  transition: transform 1s ease-in-out;
}

.container-fluid {
  display: flex;
  flex-direction: column;
}
.appopen{
  margin-left: 247px;
}
.timeline {
  margin-left: 3px;
  border-radius: 5px;
  display: none;
  background-color: var(--white-color);
}

.show{
  display: block;
}

.header {
  position: fixed;
  display: flex;
  align-items: center;
  background-color: #fafafa;
  z-index: 12;
  transition: width 0.3s;
}

.button-menu-mobile{
  z-index: 10;
  position: absolute;
  left: -15px;
}
.header-open {
  width: calc(100% - 247px);
}

.header-closed {
  width: 100%;
}

.text-exs{
  font-size: 11px;
}
.text-exss{
  font-size: 8px;
}

.dataTables_length{
  display: flex;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: .85rem;
  gap: 5px;
}

select{ 
border-radius: 5px;
}
.dataTables_empty{
  text-align: center;
  font-size: 12px;
}
.dataTables_filter{
  display: flex;
  padding-top: 0.25rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  font-size: .85rem;
  gap: 5px;
}

.dataTables_filter input{
  display: block;
    width: 100%;
    height: 25px;
    padding: 0.375rem 0.75rem;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #303e67;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e3ebf6;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}
.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

.dataTables_paginate  {
  margin: 10px;
  white-space: nowrap;
  text-align: right;
}

.paginate_button{
  padding: 0.375rem 0.75rem ;
  border: 1px solid #e3ebf6;
  cursor: pointer;
  font-size: 12px;
  color: #1778f2;
}

.paginate_button.current{
  color: var(--white-color);
  background-color: #2c29f3;
}

.paginate_button.previous{
 border-radius: 5px 0px 0px 5px;
}

.ellipsis{
  padding: 0.375rem 0.75rem ;
  border: 1px solid #e3ebf6;
  cursor: pointer;
  font-size: 12px;
  color: #333;
}

.disabled{
  color: #333;
}

.paginate_button.next{
  border-radius: 0px 5px 5px 0px;
}

.dataTables_info{
  margin: 10px;
  white-space: nowrap;
  text-align: right;
  font-size: 12px;
}

.pagination{
  display: flex;
  justify-content: end;
  margin: 10px 0px 10px 10px;
  white-space: nowrap;
  text-align: right;
}
.pagination li{
  padding: 0.375rem 0.75rem ;
  border: 1px solid #e3ebf6;
  cursor: pointer;
  font-size: 12px;
}
.pagination .previous {
  color: #1778f2;
}

.previous.disabled{
  color: #333;
}

.pagination .active{
 color: #fff;
 background-color: #1778f2;
}

::-webkit-scrollbar {
  width: 6px;
  height: 4px;
  background: #2e2e2e;
}

::-webkit-scrollbar-thumb {
  background: #851f1f;
  border-radius: 15px;
}